import { formatMoney } from "../Utils";

const TokenCardComponent = ({ data, onApply, onRemove }) => {
  return (
    <div className="col-12 col-md-6">
      <div class="card m-1">
        <div class="card-body">
          <h5 class="card-title">#{data.ID}</h5>
          <hr />
          <p class="card-text">
            <p>
              <span className="fw-bold">Tipo: </span>
              {data.TIPOTOKEN == "01" ? "DESCONTO" : data.TIPOTOKEN == "02" ? "PARCELAMENTO" : data.TIPOTOKEN}
            </p>
            <p>
              <span className="fw-bold">Valor: </span>
              {data.TIPOTOKEN == "01" ? formatMoney(data.VALOR) : data.VALOR}
            </p>
          </p>
          {
            data.APLICADO ?    <button href="#" class="btn btn-danger" onClick={() => onRemove(data)}>
            Remover
          </button> :    <button href="#" class="btn btn-success" onClick={() => onApply(data)}>
            Aplicar
          </button>
          }
     
        </div>
      </div>
    </div>
  );
};

export default TokenCardComponent;
